<template>
  <Modal
    class="feedback-modal"
    icon="like"
    :title="$t('modals.feedback.title')"
    @close="$emit('close')"
  >
    <template #text>
      <div class="feedback-modal__text">{{ $t('modals.feedback.text') }}</div>
    </template>
    <template #footer>
      <a href="https://www.otzyvru.com/robux-pier" target="_blank">
        <Button>
          {{ $t('modals.feedback.submit') }}
        </Button>
      </a>
    </template>
  </Modal>
</template>

<script>
import './feedback-modal.scss'
import Modal from '@/components/Base/Modal/modal.vue'
import Button from '@/components/Base/Button/Button.vue'

export default {
  name: 'FeedbackModal',
  components: {
    Button,
    Modal
  }
}
</script>
