<template>
  <div class="order-succesful">
    <template v-if="!isLoading">
      <div class="order-succesful__main__to-left" @click="changeOrder(-1)">
        <Icon
          name="/base/arrow-minimal.svg"
        />
      </div>
      <div class="order-succesful__main__to-right" @click="changeOrder(1)">
        <Icon
          name="/base/arrow-minimal.svg"
        />
      </div>
    </template>
    <form-layout
      :small-title="true"
      :title="$t('order.status')"
    >
      <div class="order-succesful__main">
        <Loader class="transferLoader" v-if="isLoading"/>

        <div v-else class="order-succesful__main__inf">
          <p v-if="currentOrderStatus === 'error'" class="order-succesful__main__inf__wait" :class="{top: currentOrderStatus === 'error'}">{{ getOrderDescription(data.orders[currentIndex]) }}</p>
          <!--          <div class="order-succesful__main__inf__num">#{{ currentIndex + 1 }}</div>-->
          <div class="order-succesful__main__inf__icon" v-if="currentOrderStatus === 'success'">
            <img :src="require('./../../../assets/images/transfer/status-yes.png')" alt="">
          </div>
          <div class="order-succesful__main__inf__icon" v-if="currentOrderStatus === 'error' || currentOrderStatus === 'system_error'">
            <img :src="require('./../../../assets/images/transfer/status-no.png')" alt="">
          </div>
          <div class="order-waiting__main__inf__timer" v-if="['waiting', 'paid', 'code', 'robuxship'].includes(currentOrderStatus)">
            <img src="@/assets/images/transfer/spiner.png" alt="">
            <p>{{ displayTimer() }}</p>
          </div>
          <p v-if="currentOrderStatus !== 'error'" class="order-succesful__main__inf__wait" :class="{top: currentOrderStatus === 'error'}">{{ getOrderDescription(data.orders[currentIndex]) }}</p>
          <p class="order-succesful__main__inf__text" v-if="currentOrderStatus === 'error'">{{ $t('transfer.orderError') }}</p>
          <div class="order-succesful__main__inf__table">
            <div class="order-succesful__main__inf__table__head">
              <div class="order-succesful__main__inf__table__head__atr start">{{ $t('order.table.nickname') }}</div>
              <div class="order-succesful__main__inf__table__head__atr">{{ $t('order.table.count') }}</div>
              <div class="order-succesful__main__inf__table__head__atr">{{ $t('order.table.total') }}</div>
            </div>
            <div class="order-succesful__main__inf__table__body">
              <div class="order-succesful__main__inf__table__body__obj start"><p>{{ data.orders[currentIndex].username }}</p></div>
              <div class="order-succesful__main__inf__table__body__obj" :class="{promo: data.orders[currentIndex].promo}">
                <template v-if="data.orders[currentIndex].promo">
                  <p>{{ data.orders[currentIndex].promo.robux_amount_no_promo.toFixed(2) }} R$</p>
                  <p class="promo">+{{ getPromoBonus(data.orders[currentIndex].promo) }} R$</p>
                </template>
                <template v-else><p>{{ data.orders[currentIndex].robux_amount.toFixed(2) }} R$</p></template>
              </div>
              <div class="order-succesful__main__inf__table__body__obj"><p>{{ getOrderTotal(data.orders[currentIndex]) }} {{ $store.getters.getCurrencySign(data.orders[currentIndex].currency.toLowerCase()) }}</p></div>
            </div>
          </div>

          <Button
            class="transfer__form-btn"
            type="secondary"
            left-icon="/base/plus.svg"
            @click="changeModal"
          >
            {{ $t('order.makeNewOrder') }}
          </Button>

          <i18n path="transfer.orderSuccess" tag="p" class="order-succesful__main__inf__text" v-if="currentOrderStatus === 'success'">
            <template v-slot:here>
              <a href="https://vk.com/away.php?to=https%3A%2F%2Fwww.roblox.com%2Ftransactions"><span>{{ $t('transfer.here') }}</span></a>
            </template>
          </i18n>
        </div>
      </div>
    </form-layout>

    <!-- Modals -->
    <FeedbackModal v-if="modals.isShowFeedback" @close="modals.isShowFeedback = false"/>
    <SocialModal v-if="modals.isShowSocial" @close="modals.isShowSocial = false"/>
  </div>
</template>

<script>
import './ordersuccesful.scss'
import './orderwaiting.scss'
import { getPromoBonus } from '@/utils/promo'
import { capitalizeFirstLetter } from '@/utils/capitalize'
import FormLayout from '@/components/Base/FormLayout/FormLayout.vue'
import Button from '@/components/Base/Button/Button.vue'
import Icon from '@/components/Base/Icon/Icon.vue'
import Loader from '@/components/LoaderBar/LoaderBar.vue'
import FeedbackModal from '@/components/Modals/Feedback/FeedbackModal.vue'
import SocialModal from '@/components/Modals/Social/SocialModal.vue'

export default {
  name: 'OrderSuccesful',
  props: ['data'],
  inject: ['currencyMap'],
  components: {
    SocialModal,
    FeedbackModal,
    Loader,
    Icon,
    Button,
    FormLayout
  },
  data () {
    return {
      currentIndex: 0,
      currentTimer: null,
      timer: null,
      intervalId: null,
      orderIId: null,
      isLoading: true,
      orders: [],
      totalOrders: 0,
      modals: {
        isShowFeedback: false,
        isShowSocial: false
      }
    }
  },
  async created () {
    this.$store.dispatch('authorization', this.$cookies.get('access_token'))
    await this.getOrders()

    this.orderIId = setInterval(() => {
      this.getOrders()
    }, 25000)
  },
  async mounted () {
    this.resetCacheShowModalOnce('feedback')
    await this.getHistory()
    this.setTimer()

    this.currentIndex = this.data?.orders?.length - 1

    this.$nextTick(() => {
      if (this.isShowSocialModal) {
        this.showModal('social') // Отображение модального окна соцсетей
        if (this.modals.isShowSocial) return
      }

      if (this.isShowFeedbackModal) {
        this.showModal('feedback') // Отображение модального окна отзывов
      }
    })
  },
  destroyed () {
    clearInterval(this.orderIId)
  },
  methods: {
    getPromoBonus,
    changeModal () {
      this.$emit('changeModal', {
        modal: 'MainForm'
      })
    },
    getOrders () {
      this.$store.dispatch('authorization', this.$cookies.get('access_token'))

      const requestHeaders = new Headers({
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })

      const requestParams = {
        method: 'GET',
        headers: requestHeaders
      }

      return fetch('https://transfer.backend-pier.com/active_orders', requestParams)
        .then((res) => res.json())
        .then((data) => {
          this.isLoading = false
          if (data.status) {
            this.data.orders = data.orders

            if (this.data.orders.length > 0) {
              this.currentTransfer = 'TransferOrder'
            }
          }
        })
        .catch((err) => console.log(err))
    },
    async getHistory () {
      await this.$store.dispatch('authorization', this.$cookies.get('access_token'))

      const requestHeaders = new Headers({
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })

      const requestParams = {
        method: 'GET',
        headers: requestHeaders
      }

      try {
        const response = await fetch('https://transfer.backend-pier.com/get_history?count=7&page=1', requestParams)
        const data = await response.json()

        if (data.status) {
          this.totalOrders = data.total_orders
          this.orders = data.orders
        }
      } catch (err) {
        console.log(err)
      }
    },
    getOrderTotal (order) {
      return order.amount[order.currency.toLowerCase()].toFixed(2)
    },
    getOrderDescription (order) {
      const code = order.code_description
      const texts = {
        0: this.$t('order.descriptions.wait'),
        1: this.$t('order.descriptions.notEnoughFunds'),
        2: this.$t('order.descriptions.systemError'),
        3: this.$t('order.descriptions.checkVip'),
        4: this.$t('order.descriptions.connectionError'),
        5: this.$t('order.descriptions.success'),
        6: this.$t('order.descriptions.checkGamepass')
      }

      return texts[code]
    },
    setTimer () {
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }

      const orderDate = new Date(this.data.orders[this.currentIndex].date)
      orderDate.setHours(orderDate.getHours() + 1)
      this.timer = orderDate - Date.now()

      this.intervalId = setInterval(() => {
        this.timer -= 1000
      }, 1000)
    },
    displayTimer () {
      if (this.timer <= 0) {
        return '00:00:00'
      }

      const formatedHours = ('0' + String(Math.trunc(this.timer / 1000 / 60 / 60))).slice(-2)
      const formatedMinutes = ('0' + String(Math.trunc(this.timer / 1000 / 60 % 60))).slice(-2)
      const formatedSeconds = ('0' + String(Math.trunc(this.timer / 1000 % 60 % 60))).slice(-2)

      return `${formatedHours}:${formatedMinutes}:${formatedSeconds}`
    },
    changeOrder (direction) {
      if (direction === 1) {
        this.currentIndex = this.currentIndex + 1 >= this.data.orders.length ? 0 : this.currentIndex + 1
      } else if (direction === -1) {
        this.currentIndex = this.currentIndex - 1 < 0 ? this.data.orders.length - 1 : this.currentIndex - 1
      }

      this.setTimer()
    },
    showModal (modal) {
      if (!localStorage.getItem(`${modal}ModalShownPier`)) {
        const capitalizedModal = capitalizeFirstLetter(modal)
        this.modals[`isShow${capitalizedModal}`] = true
      }

      localStorage.setItem(`${modal}ModalShownPier`, '1')
    },
    resetCacheShowModalOnce (modal) {
      if (!localStorage.getItem(`${modal}ModalIsResetPier`)) {
        localStorage.removeItem(`${modal}ModalShownPier`)
        localStorage.setItem(`${modal}ModalIsResetPier`, '1')
      }
    }
  },
  computed: {
    currentOrderStatus () {
      return this.data.orders[this.currentIndex].status
    },
    isShowFeedbackModal () {
      const completedSuccessOrdersLength = this.orders?.filter(order => order.status === 'success').length

      return this.totalOrders >= 2 && !!completedSuccessOrdersLength
    },
    isShowSocialModal () {
      return this.totalOrders >= 1
    }
  }
}
</script>
